import {
  GET_INVENTORY_FAILURE,
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  STOCK_FORM_DATA,
  SET_CART_DATA,
  GET_UNITS_LIST,
  GET_PURCHASE_ORDERS,
  GET_PURCHASEORDER_DETAILS,
  GET_REQUISITION_ORDERS,
  GET_VENDORS_LISTING,
  GET_STOCKTRANSACTION_HISTORY,
  CART_TRANSACTION,
  GET_REMARKS_LIST,
  SET_VALIDPRODS,
  GET_VENDOR_INVOICES,
  GET_INVOICE_PAYINFO,
  GET_PRODUCTS_BYCHAIN,
  GET_PRODUCTS_TRIMMED_BYCHAIN,
  GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN,
  GET_BATCHES_FAILURE,
  GET_BATCHES_REQUEST,
  GET_BATCHES_SUCCESS,
  GET_BATCH_PRODUCTS,
  GET_BATCH_PRODUCTS_LIST,
  GET_REQUISITION_HISTORY,
  GET_REQUISITION_IDS,
  FETCH_LOCALITY_ORDERS_SUCCESS,
  GET_CHAIN_ECOM_STORES_SUCCESS,
  STORE_LOCALITY_MAPPING_SUCCESS,
  DISPATCH_ORDER_SUCCESS,
  CLEAR_DISPATCH_ORDER_STATUS,
  FETCH_DO_SUMMARY_SUCCESS,
  FETCH_DO_DETAIL_SUCCESS,
  GET_STOCKCHAINPRODS_FAILURE,
  GET_STOCKCHAINPRODS_SUCCESS,
  GET_STOCKCHAINPRODS_REQUEST,
  LOGOUT,
  CHECK_VENDORLIST_REQUEST,
  CHECK_VENDORLIST_SUCCESS,
  CHECK_VENDORLIST_FAILURE,
  CHECK_VENDOR_MAPPING_REQUEST,
  CHECK_VENDOR_MAPPING_SUCCESS,
  CHECK_VENDOR_MAPPING_FAILURE,
  GET_PRODUCTS_BYCHAIN_EXCEL,
  GET_STORE_CATALOGUE_LIST_FAILURE,
  GET_STORE_CATALOGUE_LIST_SUCCESS,
  GET_STORE_CATALOGUE_LIST_REQUEST,
  GET_BATCHES_WEB_REQUEST,
  GET_BATCHES_WEB_SUCCESS,
  GET_BATCHES_WEB_FAILURE,
  CHAIN_LEVEL_STORES,
  GET_PRODUCTS_PROMOTION,
  GET_PAYMENT_LIST,
  FETCH_INVENTORY_ASSETS_REQUEST,
  FETCH_INVENTORY_ASSETS_SUCCESS,
  FETCH_INVENTORY_ASSETS_FAILURE
} from "../actions/types";

const inventoryDataState = [
  {
    inventoryData: [],
    isLoading: false,
  },
];

const getInventoryBatchStatus = {
  data: [],
  isLoading: null,
};

const getBatchProducts = {
  data: [],
};

const getRequisitionIds = {
  data: [],
};

const batchProductList = {
  data: [],
};

const stockFormDataState = [
  {
    stockFormData: {},
  },
];

const setCartDataState = [
  {
    setCartData: {},
  },
];

const unitsList = [
  {
    unitsList: [],
  },
];

const purchaseOrder = [
  {
    purchaseOrder: [],
  },
];

const purchaseOrdersDetail = [
  {
    data: [],
  },
];

const requisitionOrdersDetail = [{}];

const vendorsList = [{}];

const stockHistory = [{}];

const stockReqHistory = [{}];

const transactionResponse = [{}];

const remarksList = {
  data: [],
};

const setValidProdsState = [{}];

const invoices = [{}];

const invoicePaymentInfo = [{}];

const productsByChain = [{}];
const exportProductsListChainWebState = [{}];

const productsListTrimmedWebState = [{}];

const productsListTrimmedWebWithCombosState = [{}];

const productsByChainPromotion = [{}];

const stockProductsListChainStatus = {
  data: [],
  isLoading: null,
};

const getSourceVendorsList = {
  data: [],
  isLoading: null,
};

const getVendorProductMapping = {
  data: [],
  isLoading: null,
};

const getStoreCatalogueListState = {
  data: [],
  isLoading: null,
};

const getBatchesWebReducerState = {
  data: [],
  isLoading: null,
};

const getStoreWare = {
  data : []
}

const getInventoryAsset =  {
  data:[],
  isLoading:null
}

export const getInventory = (state = inventoryDataState, { type, payload }) => {
  

  switch (type) {
    case GET_INVENTORY_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_INVENTORY_SUCCESS:
      state.inventoryData = payload;
      state.isLoading = false;
      return {
        ...state,
      };
    case GET_INVENTORY_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const batches = (state = getInventoryBatchStatus, { type, payload }) => {
  switch (type) {
    case GET_BATCHES_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_BATCHES_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case GET_BATCHES_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const batchProds = (state = getBatchProducts, { type, payload }) => {
  switch (type) {
    case GET_BATCH_PRODUCTS:
      state.data = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getBatchProductsList = (
  state = batchProductList,
  { type, payload }
) => {
  switch (type) {
    case GET_BATCH_PRODUCTS_LIST:
      state.data = payload;
      return {
        ...state,
      };
    default:
      return state;
    }
};

export const stockFormData = (
  state = stockFormDataState,
  { type, payload }
) => {
  // 

  switch (type) {
    case STOCK_FORM_DATA:
      state.stockFormData = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const setCartData = (state = setCartDataState, { type, payload }) => {
  // 

  switch (type) {
    case SET_CART_DATA:
      state.setCartData = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getUnitsList = (state = unitsList, { type, payload }) => {
  // 

  switch (type) {
    case GET_UNITS_LIST:
      state.unitsList = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getPurchaseOrders = (state = purchaseOrder, { type, payload }) => {
  // 

  switch (type) {
    case GET_PURCHASE_ORDERS:
      state.purchaseOrder = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getPurchaseOrdersDetail = (
  state = purchaseOrdersDetail,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_PURCHASEORDER_DETAILS:
      state.data = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getDetails = (
  state = requisitionOrdersDetail,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_REQUISITION_ORDERS:
      state.requisitionOrdersDetail = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getVendorsListing = (state = vendorsList, { type, payload }) => {
  // 

  switch (type) {
    case GET_VENDORS_LISTING:
      state.vendorsList = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getStockRequisitionsWeb = (
  state = getRequisitionIds,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_REQUISITION_IDS:
      state.data = payload;

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const stockTransactionHistory = (
  state = stockHistory,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_STOCKTRANSACTION_HISTORY:
      state.stockHistory = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const stockRequestHistory = (
  state = stockReqHistory,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_REQUISITION_HISTORY:
      state.stockReqHistory = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const stockTransaction = (
  state = transactionResponse,
  { type, payload }
) => {
  // 

  switch (type) {
    case CART_TRANSACTION:
      state = payload;
      

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const remarks = (state = remarksList, { type, payload }) => {
  switch (type) {
    case GET_REMARKS_LIST:
      state.data = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const setValidProds = (
  state = setValidProdsState,
  { type, payload }
) => {
  

  switch (type) {
    case SET_VALIDPRODS:
      state.setValidProdsState = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const vendorInvoices = (state = invoices, { type, payload }) => {
  

  switch (type) {
    case GET_VENDOR_INVOICES:
      state.invoices = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const getInvoicePaymentInfo = (
  state = invoicePaymentInfo,
  { type, payload }
) => {
  

  switch (type) {
    case GET_INVOICE_PAYINFO:
      state.invoicePaymentInfo = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const productsChain = (state = productsByChain, { type, payload }) => {
  // 

  switch (type) {
    case GET_PRODUCTS_BYCHAIN:
      state.productsByChain =
        payload &&
        payload.productsListChain &&
        payload.productsListChain.data.data;
        state.isFirstTimeSetupStatus = payload &&
        payload.productsListChain &&
        payload.productsListChain.data.isFirstTimeSetup
      return {
        ...state,
      };
    case LOGOUT:
      return [{}];
    default:
      return state;
  }
};

export const productsListTrimmedWeb = (
  state = productsListTrimmedWebState,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_PRODUCTS_TRIMMED_BYCHAIN:
      state.productsListTrimmedWebState =
        payload &&
        payload.productsListTrimmed &&
        payload.productsListTrimmed.data.data;
        state.isFirstTimeSetupStatus =
        payload &&
        payload.productsListTrimmed &&
        payload.productsListTrimmed.data.isFirstTimeSetup;
      return {
        ...state,
      };
    case LOGOUT:
      return [{}];
    default:
      return state;
  }
};

export const productsListTrimmedWebWithCombos = (
  state = productsListTrimmedWebWithCombosState,
  { type, payload }
) => {

  switch (type) {
    case GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN:
      state.productsListTrimmedWebWithCombosState = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const exportProductsListChainWeb = (
  state = exportProductsListChainWebState,
  { type, payload }
) => {
  // 

  switch (type) {
    case GET_PRODUCTS_BYCHAIN_EXCEL:
      state.exportProductsListChainWebState =
        payload &&
        payload.exportProductsListChain &&
        payload.exportProductsListChain.data.data;
      return {
        ...state,
      };
    case LOGOUT:
      return [{}];
    default:
      return state;
  }
};

export const productsChainPromotion = (state = productsByChainPromotion, { type, payload }) => {
  // 

  switch (type) {
    case GET_PRODUCTS_PROMOTION:
      state.productsByChainPromotion =
        payload &&
        payload.productsListChainPromotion &&
        payload.productsListChainPromotion.data.data;
        state.isFirstTimeSetupStatus = payload &&
        payload.productsListChainPromotion
      return {
        ...state,
      };
    case LOGOUT:
      return [{}];
    default:
      return state;
  }
};

export const fetchPaymentValues = (state = [], { type, payload }) => {
  switch (type) {
    case GET_PAYMENT_LIST:
      state = payload;
      return state;
    default:
      return state;
  }
};

export const isFirstTimeSetup = (state = 0, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS_BYCHAIN:
      let newState =
        payload &&
        payload.productsListChain &&
        payload.productsListChain.data.isFirstTimeSetup;
      return newState;
    default:
      return state;
  }
};

export const localityOrders = function (state = null, action) {
  switch (action.type) {
    case FETCH_LOCALITY_ORDERS_SUCCESS:
      return action.payload.invoice;
    default:
      return state;
  }
};

export const chainEcomStores = function (state = null, action) {
  switch (action.type) {
    case GET_CHAIN_ECOM_STORES_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

export const localityMapping = function (state = null, action) {
  switch (action.type) {
    case STORE_LOCALITY_MAPPING_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

export const dispatchOrderStatus = function (state = null, action) {
  switch (action.type) {
    case DISPATCH_ORDER_SUCCESS:
      return action.payload;
    case CLEAR_DISPATCH_ORDER_STATUS:
      return null;
    default:
      return state;
  }
};

export const doSummary = function (state = null, action) {
  switch (action.type) {
    case FETCH_DO_SUMMARY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const doDetail = function (state = null, action) {
  switch (action.type) {
    case FETCH_DO_DETAIL_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

export const stockProductsListChain = (
  state = stockProductsListChainStatus,
  { type, payload }
) => {
  switch (type) {
    case GET_STOCKCHAINPRODS_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_STOCKCHAINPRODS_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case GET_STOCKCHAINPRODS_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const sourceVendorsList = (
  state = getSourceVendorsList,
  { type, payload }
) => {
  switch (type) {
    case CHECK_VENDORLIST_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case CHECK_VENDORLIST_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case CHECK_VENDORLIST_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const vendorProductMapping = (
  state = getVendorProductMapping,
  { type, payload }
) => {
  switch (type) {
    case CHECK_VENDOR_MAPPING_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case CHECK_VENDOR_MAPPING_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case CHECK_VENDOR_MAPPING_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};
export const getStoreCatalogueList = (
  state = getStoreCatalogueListState,
  action
) => {
  switch (action.type) {
    case GET_STORE_CATALOGUE_LIST_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_STORE_CATALOGUE_LIST_SUCCESS:
      state.isLoading = false;
      state.data = action.payload;
      return {
        ...state,
      };
    case GET_STORE_CATALOGUE_LIST_FAILURE:
      state.isLoading = false;
      state.data = {
        ...state,
      };
    default:
      return state;
  }
};

export const getBatchesWebReducer = (
  state = getStoreCatalogueListState,
  action
) => {
  switch (action.type) {
    case GET_BATCHES_WEB_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_BATCHES_WEB_SUCCESS:
      state.isLoading = false;
      state.data = action.payload;
      return {
        ...state,
      };
    case GET_BATCHES_WEB_FAILURE:
      state.isLoading = false;
      state.data = {
        ...state,
      };
    default:
      return state;
  }
};

export const getChainStoreWareReducer = (state = getStoreWare,action) => {
  switch (action.type) {
    case CHAIN_LEVEL_STORES:
      state.isLoading = false;
      state.data = action.payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const fetchInventoryAsset= (state = getInventoryAsset, { type, payload }) => {
  switch (type){
      case FETCH_INVENTORY_ASSETS_REQUEST:
          state.isLoading = true;
          return {
              ...state
          }
          case FETCH_INVENTORY_ASSETS_SUCCESS:
          state.isLoading = false;
          state.data=payload;
          return {
              ...state
          }
          case FETCH_INVENTORY_ASSETS_FAILURE:
              state.isLoading = false;
              return {
                  ...state
              }
      default:
          return state;
  }
}
