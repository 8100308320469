import React from "react";
import currencyMapping from './currencyMapping';
import countries from "../components/common/countries";
import { FormattedMessage } from "react-intl";
import messages from "../Internationalisation/MessageDescriptor";
import moment from "moment";

window.selectedSort = function (arr, selectedArr, key) {
   let i = -1;

   for (let j = 0; j < arr.length; j++) {
      if (selectedArr.includes(arr[j][key])) {
         let t = arr[i + 1];
         arr[i + 1] = arr[j];
         arr[j] = t;
         ++i;
      }
   }
};

window.isUndefinedEmpty = (value) => {
   return value === undefined || value === "" || (value instanceof String && value.trim() === "");
};

window.isUndefinedEmptyNaN = (value) => {
   return (
      value === "" ||
      value === undefined ||
      isNaN(parseInt(value)) ||
      (value instanceof String && value.trim() === "")
   );
};

window.isUndefinedEmptyNull = (value) => {
   return (
      value === "" ||
      value === undefined ||
      value === null ||
      (value instanceof String && value.trim() === "")
   );
};

function foreignSystem(x) {
   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function indianSystem(x) {
   x = x.toString();
   let lastThree = x.substring(x.length - 3);
   let otherNumbers = x.substring(0, x.length - 3);
   if (otherNumbers != "") lastThree = "," + lastThree;
   let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
   return res;
}

window.currencySanity = (value, currencyCode) => {
   if (value === undefined || value === null) return value;
   let v1 = value.toString().trim();

   let v2 = parseFloat(v1);
   if (isNaN(v2)) return value;

   let isNegative = v2 < 0;
   let absV2 = isNegative ? -1 * v2 : v2;

   let v3 = absV2.toFixed(2);

   let v3Split = v3.split(".");
   if (currencyCode === "INR") {
      v3Split[0] = indianSystem(v3Split[0]);
   } else {
      v3Split[0] = foreignSystem(v3Split[0]);
   }

   let v4 = v3Split.join(".");

   let v5 = (isNegative ? "- " : "") + currencyMapping(currencyCode) + (isNegative ? "" : " ") + v4;
   return v5;
};
window.currencySymbol = (currencyCode) => {
   return Intl.NumberFormat("en-US", { style: "currency", currency: currencyCode })
      .formatToParts("")
      .find((x) => x.type === "currency").value;
};
window.toBase64 = (file) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });

window.setReportGlobalFilters = (key, value) => {
   let filters = JSON.parse(sessionStorage.getItem("report_filters"));
   if (!filters) filters = {};
   filters[key] = value;
   sessionStorage.setItem("report_filters", JSON.stringify(filters));
};

window.getReportGlobalFilters = () => JSON.parse(sessionStorage.getItem("report_filters"));

window.sanitizePathname = (pathname) => {
   if (pathname.charAt(pathname.length - 1) === "/")
      return pathname.substring(0, pathname.length - 1);
   else return pathname;
};

window.splitIntoWords = (s) => {
   let output;
   output = s.charAt(0).toUpperCase() + s.substring(1);
   output = output.split(/(?=[A-Z])/).join(" ");
   return output;
};

window.intArrayIntersection = (arr1, arr2) => {
   if (arr1.length !== arr2.length) return false;
   let map = {};
   arr1.map((v, i) => {
      map[v] = i;
   });
   for (let i = 0; i < arr2.length; i++) {
      if (map[arr2[i]] === undefined) return false;
   }
   return true;
};

window.getURLSearchObject = (searchString) => {
   let s = searchString.substring(1);
   let a = s.split("&");
   let output = {};
   a.map((v) => {
      let ar = v.split("=");
      output[ar[0]] = ar[1];
   });
   return output;
};

window.debounce = (call, delay) => {
   let timer;
   return function (...args) {
      // Call debounce into an variable with their args and call it where we want with some delay.
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
         call(...args);
      }, delay);
   };
};

window.roundDecimal = (num) => {
   let value = Number(num);
   return Math.round((value + Number.EPSILON) * 100) / 100;
};

window.getCallingCode = (countryName) => {
   let filteredCountryData = countries?.find((elem) => elem.name == countryName);
   return filteredCountryData.callingCodes;
};

/* This is the main run-time calculation function for billing */
export const handleBillingCheckout = ({
    state,
    items,
    chargeTaxList,
    appSettings,
    lastOrderID,
    units,
    authData,
    PIPayload,
    initNotifier,
    calculateForUI,
 }) => {
    if(!appSettings.storeID) return;
    /* Checking for customer on the order if mandate customer is enabled */
    if (!calculateForUI && appSettings.mandateCustomerInfoInOrder && !state.selectedCustomer.customerID) {
       initNotifier({
          actionType: "ERROR_NOTIFIER",
          msg: <FormattedMessage {...messages.billing.common.addCust} children={(a) => a} />,
       });
       return;
    }
    /* Checking the maximum line items if the setting for maxLineItemPerOrder is enabled and not equals to 0 */
    if (!calculateForUI && appSettings.maxLineItemPerOrder && items.length > appSettings.maxLineItemPerOrder) {
       initNotifier({
          actionType: "ERROR_NOTIFIER",
          msg: <FormattedMessage {...messages.billing.common.prodMaxLimit} children={(a) => a} />,
       });
       return;
    }
    let billingStoreDetails = JSON.parse(localStorage.getItem("billingStoreDetails")),
       isInterstate = false;
    /* Checking if the customer added is company, GST validation is enabled and order is interState i.e. between two different states */
    if (appSettings.enableGSTValidation && state.selectedCustomer.customerID) {
       if (state.selectedCustomer.customerAddress && state.selectedCustomer.customerAddress.length) {
          let shippingAddress = state.selectedCustomer.customerAddress?.find(
             (el) => el.addressType === "SHIPPING"
          );
          if (
             state.selectedCustomer.customerType === "COMPANY" &&
             shippingAddress &&
             shippingAddress.state != billingStoreDetails.outletAddState
          ) {
             isInterstate = true;
          }
       }
    }
    let { chargeList = [] } = chargeTaxList,
       additionalCharges = chargeList?.filter((e) =>
          state.selectedCharges.includes(e.additionalChargeID)
       ),
       taxes = 0;
    const { taxList = [] } = chargeTaxList;
    let storeID = localStorage.getItem("billingStore");
    let deviceID = localStorage.getItem("deviceID");
    let id = "";
    if (lastOrderID) {
       let splittedLastOrder = String(lastOrderID).split("-");
       id = splittedLastOrder[splittedLastOrder.length - 1];
       if (id) {
          id = String(Number(id) + 1);
       }
    } else {
       id = 1;
    }
    if (!calculateForUI && !id) {
       initNotifier({
          actionType: "ERROR_NOTIFIER",
          msg: "Incorrect Order Sequence",
       });
       return;
    }
    const token = authData.qbSessionID;
    /* Handling Price List if setting is enabled and there's a price list mapped to the customer on order */
    if (
       appSettings.enablePriceList &&
       state.selectedCustomer.customerID &&
       state.selectedCustomer.priceListID
    ) {
       let selectedCustPriceList = state.priceList?.find(
          (elem) => elem.ID == state.selectedCustomer.priceListID
       );
       items = items?.map((el) => {
          let priceListItem = state.priceListDetail?.find((elem) => elem.productID === el.productID);
          if (priceListItem && selectedCustPriceList) {
             if (selectedCustPriceList.priceRule === "CUSTOM") {
                el.price = priceListItem.price;
             } else if (selectedCustPriceList.priceRule === "MARK_UP") {
                el.price =
                   selectedCustPriceList.marginType === "ABSOLUTE"
                      ? Number(el.price) + Number(selectedCustPriceList.marginValue)
                      : Number(el.price) +
                        Number(el.price) * Number(selectedCustPriceList.marginValue) * 0.01;
             } else {
                el.price =
                   selectedCustPriceList.marginType === "ABSOLUTE"
                      ? Number(el.price) - Number(selectedCustPriceList.marginValue)
                      : Number(el.price) -
                        Number(el.price) * Number(selectedCustPriceList.marginValue) * 0.01;
             }
          }
          if (Number(el.price) < 0) el.price = 0;
          return el;
       });
    }
    /* Calling the function handleDiscount to maintain the initial payload with discounts on each product */
    let modifiedCartItems = handleDiscount(items, state, appSettings, chargeTaxList);
    /* Iterarting through the discounted cart items in order to perform further calculations */
    modifiedCartItems = modifiedCartItems?.map((e, idx) => {
       let { itemSales, productBasePrice, discounts } = e;
       let totalDiscount = discounts?.reduce((acc, dis) => acc + dis.discountPrice, 0);
       /* Adding charges to each item present in the cart */
       let filteredCharges = [];
       if (e.isOpenItem) {
          filteredCharges = chargeList?.filter((charge) =>
             e.additionalChargeIDs
                .toString()
                .split(",")
                .map((elem) => Number(elem))
                .includes(charge.additionalChargeID)
          );
       } else {
          filteredCharges = additionalCharges?.filter((elem) =>
             e.additionalChargeIDs
                .toString()
                .split(",")
                .map((elem) => Number(elem))
                .includes(elem.additionalChargeID)
          );
       }
       /* Hanlding Product Level ( Percentage & Absolute ) */
       const charges = filteredCharges?.map((charge) => {
          let {
                accountID,
                accountName,
                additionalChargeID,
                chargeType,
                value,
                appliedOnLevel,
                applyOnlyOnOnlineOrders,
                isActive,
                isAssignedToStore,
                name
             } = charge,
             obj = {},
             additionalChargePrice = 0;
          if (appliedOnLevel == "PRODUCT") {
             if (chargeType == "ABSOLUTE") {
                additionalChargePrice = Number(value) * Number(e.quantity);
             } else if (chargeType == "PERCENTAGE") {
                additionalChargePrice = value * 0.01 * (Number(itemSales) - Number(totalDiscount));
             }
             obj = {
                accountID,
                accountName,
                additionalChargeID,
                additionalChargeName: name,
                additionalChargePrice: Number(additionalChargePrice),
                additionalChargeValue: value,
                appliedOnLevel,
                applyOnlyOnOnlineOrders,
                chargeType,
                isActive,
                isAssignedToStore,
                oldProdList: [],
                prodList: [],
                taxes: [],
             };
          }
          return obj;
       });
       e.additionalCharges = charges;
       e.additionalChargeValue = charges?.reduce(
          (acc, elem) => acc + Number(elem.additionalChargePrice),
          0
       );
       const { data } = units;
       const matchedUnit = data?.find((unit) => unit.toUnit == e.soldIn);
       return {
          ...e,
          taxableValue: 0,
          totalTaxApplied: [],
          productID: e.productID || e.comboProductID,
          productName: e.productName || e.comboName,
          variantID: e.variantID || e.comboVariantID,
          batchID: -1,
          batchVariantID: e.batchVariantID || -1,
          batchVariantName: e.batchVariantName || "",
          billingUsername: authData.username,
          orderTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
          timezone: authData.timezone,
          voidRemarks: "",
          Status: "ORST_DELIVERED",
          itemSales: Number(itemSales),
          productValue: 0,
          quantityOrdered: e.quantity,
          hasModifiers: e.modifiersList ? ~~Boolean(e.modifiersList.length) : 0,
          isNoCharge: 0,
          orderSubID: idx + 1,
          taxes: [],
          attributes: [],
          comboGroupID: -1,
          comboGroupName: "",
          comboProductID: -1,
          comboProductName: "",
          comboProductsList: e.comboProductsList || [],
          membershipID: "",
          membershipProducts: e.membershipProducts || [],
          membershipTracker: e.membershipTracker || {
             discountTotalValue: 0,
             discountType: "",
             discountValue: 0,
             membershipID: -1,
             membershipName: "",
             membershipVariantID: -1,
             purchasedOrderID: "",
             purchasedOrderSubID: "",
          },
          isOpenItem: 0,
          isFreeProduct: 0,
          displayQuantityOrdered: e.quantity,
          displayUnitName: matchedUnit && matchedUnit.name,
          displayUnitID: +e.soldIn,
          kotNumber: -1,
          kotRemarks: "",
          productActualPrice: window.roundDecimal(e.price),
          productBasePrice: window.roundDecimal(Number(productBasePrice)),
          productOriginalPrice: window.roundDecimal(e.price),
          productType: e.productType || "OTHER",
          productGroupBarcode: "",
          serviceDuration: 0,
          serviceDurationUnit: "min",
          validityDuration: e.validityDuration || 0,
          validityDurationUnit: e.validityDurationUnit || "",
          soldIn: +e.soldIn,
          voidAmount: 0,
          voidQuantity: 0,
          weightUnit: +e.soldIn,
          startTimeLocal: "",
          endTimeLocal: "",
          orderID: "",
       };
    });
 
    /* Handling Order Level Charge */
    let filteredOrderLevelCharge = additionalCharges?.filter(
       (charge) => charge.appliedOnLevel == "ORDER"
    );
    let totalBasePrice = modifiedCartItems?.reduce((acc, prod) => acc + Number(prod.itemSales), 0);
    let charges = filteredOrderLevelCharge?.map((charge) => {
       let obj = {
          accountID: charge.accountID,
          accountName: charge.accountName,
          additionalChargeID: charge.additionalChargeID,
          additionalChargeName: charge.name,
          additionalChargePrice:
             charge.chargeType == "ABSOLUTE" ? charge.value : charge.value * 0.01 * totalBasePrice,
          additionalChargeValue: charge.value,
          appliedOnLevel: charge.appliedOnLevel,
          applyOnlyOnOnlineOrders: charge.applyOnlyOnOnlineOrders,
          chargeType: charge.chargeType,
          isActive: charge.isActive,
          isAssignedToStore: charge.isAssignedToStore,
          oldProdList: [],
          prodList: [],
          storeID: -1,
          taxes: [],
          userID: -1,
       };
       return obj;
    });
    let totalChargeValue = window.roundDecimal(
       charges?.reduce((acc, elem) => acc + elem.additionalChargePrice, 0)
    );
    modifiedCartItems = modifiedCartItems?.map((item) => {
       let discounts = item.discounts;
       /* Adding discountRemarks with all discounts if the setting is enabled enterVoucherCodeOnRuntimeDiscount and we're getting voucherCode from the UI */
       if (appSettings.enterVoucherCodeOnRuntimeDiscount) {
          discounts = discounts?.map((discount) => {
             let allVoucherCodes = state.voucherCode,
                discountRemarks = "";
             if (discount.discountID in allVoucherCodes) {
                discountRemarks = allVoucherCodes[discount.discountID];
             }
             discount.discountRemarks = discountRemarks;
             return discount;
          });
       }
       let discountValues = 0;
       discountValues = discounts?.reduce((acc, elem) => acc + elem.discountPrice, 0);
       item.discounts = discounts;
       item.appliedDiscount = discounts;
       item.discountValues = discountValues;
       item.taxableValue = window.roundDecimal(
          Number(item.itemSales) - discountValues + Number(item.additionalChargeValue)
       );
       item.taxableAmount = item.taxableValue;
 
       /* Final Tax Handling */
       let taxesApplied = taxList
          .filter(
             (tax) =>
                item.taxIDs &&
                item.taxIDs
                   .toString()
                   .split(",")
                   .map((elem) => Number(elem))
                   .includes(tax.taxID) &&
                (isInterstate ? tax.isIGST != 0 : tax.isIGST == 0)
          )
          .map((tax) => {
             return {
                accountID: tax.accountID,
                accountName: tax.accountName,
                isActive: tax.isActive,
                isAssignedToStore: tax.isAssignedToStore,
                isIGST: tax.isIGST,
                nameOnBill: tax.nameOnBill,
                parentTaxID: tax.parentTaxID,
                taxID: tax.taxID,
                taxName: tax.taxName,
                taxPercentage: tax.taxPercentage,
                taxValue: window.roundDecimal(
                   Number(tax.taxPercentage) * 0.01 * Number(item.taxableValue)
                ),
                userID: -1,
                oldProdList: [],
                prodList: [],
                storeID: -1,
             };
          });
       taxes = taxesApplied.reduce((acc, elem) => acc + Number(elem.taxValue), 0);
       item.totalTaxApplied = taxes;
       item.taxes = taxesApplied;
       item.productValue = window.roundDecimal(Number(item.taxableValue) + taxes);
       if (
          appSettings.allowOpenBill &&
          Number(state.fixedCartValue) >= 0 &&
          typeof state.fixedCartValue == "number"
       ) {
          item.additionalChargeValue = 0;
          item.additionalCharges = [];
          item.appliedDiscount = [];
          item.discounts = [];
          item.discountValues = 0;
          item.itemSales = 0;
          item.productValue = 0;
          item.taxableValue = 0;
          item.taxes = [];
          item.totalModifierValue = 0;
          item.totalTaxApplied = 0;
       }
       return item;
    });
    items = JSON.parse(JSON.stringify(modifiedCartItems));
    const chargeValue = items.reduce((acc, elem) => acc + Number(elem.additionalChargeValue), 0);
    const discountValue = items.reduce((acc, elem) => acc + Number(elem.discountValues), 0);
    const itemSales = items.reduce((acc, elem) => acc + elem.itemSales, 0);
    let grossBill = items.reduce((acc, elem) => acc + Number(elem.productValue), 0);
    grossBill += Number(totalChargeValue);
    let customer = state.selectedCustomer;
    if (customer && customer.customerID) {
       customer.loyaltyPointsRedeemed = 0;
    }
    let taxesSummedUp = items.reduce((acc, elem) => acc + elem.totalTaxApplied, 0);
    let netBill = window.roundDecimal(Number(itemSales - discountValue + chargeValue));
 
    /* Handling Open Bill Payload ( Fixed Price Sale ) - Setting Based */
    let openBill = null;
    if (
       appSettings.allowOpenBill &&
       Number(state.fixedCartValue) >= 0 &&
       typeof state.fixedCartValue == "number"
    ) {
       let { fixedCartValue = 0, fixedCartTaxIDs = [], fixedValueIncludesTaxes = 0 } = state;
       openBill = {
          openBillAmount: 0,
          isPriceInclusive: 0,
          openBillTaxes: [],
       };
       openBill["openBillAmount"] = fixedCartValue;
       openBill["isPriceInclusive"] = fixedValueIncludesTaxes;
       let taxesApplied = [],
          totalTaxPercentage = 0;
       taxesApplied = taxList.filter(
          (tax) => fixedCartTaxIDs.includes(tax.taxID) && tax.isIGST == 0
       );
       totalTaxPercentage = taxesApplied.reduce((acc, elem) => acc + Number(elem.taxPercentage), 0);
       if (fixedValueIncludesTaxes) {
          fixedCartValue = window.roundDecimal(
             (Number(fixedCartValue) * 100) / (100 + totalTaxPercentage)
          );
       }
       let taxableValue = fixedCartValue;
       let openBillTaxes = taxList
          .filter((tax) => fixedCartTaxIDs.includes(tax.taxID) && tax.isIGST == 0)
          .map((tax) => {
             return {
                accountID: tax.accountID,
                accountName: tax.accountName,
                isActive: tax.isActive,
                isAssignedToStore: tax.isAssignedToStore,
                isIGST: tax.isIGST,
                nameOnBill: tax.nameOnBill,
                parentTaxID: tax.parentTaxID,
                taxID: tax.taxID,
                taxName: tax.taxName,
                taxPercentage: tax.taxPercentage,
                taxValue: window.roundDecimal(
                   Number(tax.taxPercentage) * 0.01 * Number(taxableValue)
                ),
                userID: -1,
                oldProdList: [],
                prodList: [],
                storeID: -1,
             };
          });
       let totalOpenBillTaxApplied = openBillTaxes?.reduce((acc, elem) => acc + elem.taxValue, 0);
       openBill["openBillTaxes"] = openBillTaxes;
       grossBill += fixedCartValue + totalOpenBillTaxApplied;
       grossBill = window.roundDecimal(grossBill);
       netBill += fixedCartValue;
       taxesSummedUp += totalOpenBillTaxApplied;
    }
 
    /* Checking if rounding is enabled. Rounding off the grossBill before adding it to the final payload */
    let roundedGrossBill = Number(grossBill);
    if (appSettings.disableRoundingOfFinalAmount) {
       roundedGrossBill = Math.round(grossBill);
    }
    let rounding = window.roundDecimal(roundedGrossBill - grossBill);
 
    let PINumber = "";
    if (PIPayload) {
       PINumber = PIPayload.proformaInvoiceID || "";
    }
 
    /* Managing Billing Payload */
    let billingPayload = {
       ...(Boolean(openBill) && { openBill }),
       productsList: items,
       customers: customer.customerID ? [customer] : [],
       customerID: customer.customerID ? customer.customerID : 0,
       additionalCharges: charges,
       deliveryStatus: "ORST_DELIVERED",
       CANumber: "",
       IRN: "",
       PINumber,
       Status: "ORST_DELIVERED",
       amountDue: 0,
       appVersion: "test-web",
       buildVariant: "beta",
       appointmentID: "",
       batchID: "-1",
       billPrintTimeLocal: "",
       billSettledTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
       billingUsername: authData.username,
       chainID: authData.chainID,
       cardBalance: 0,
       cardNumber: "",
       cashTendered: 0,
       changeAmount: 0,
       counterID: 0,
       counterName: "",
       currency: authData.currencyCode,
       deliveryPlatform: "",
       deliveryDate: "",
       deviceID,
       discountValue: window.roundDecimal(discountValue),
       dueDate: "",
       ewayBillIRN: [],
       ewayBills: [],
       ewaybillsNo: "",
       externalOrderID: "",
       grossBill: Number(roundedGrossBill),
       isBillPrinted: 0,
       isKotReprint: 0,
       isNoCharge: 0,
       isOnlineOrder: 0,
       isOpenBill:
          appSettings.allowOpenBill &&
          Number(state.fixedCartValue) >= 0 &&
          typeof state.fixedCartValue == "number"
             ? 1
             : 0,
       isOrderRounded: !appSettings.disableRoundingOfFinalAmount ? 0 : 1,
       isPinelabCloud: 0,
       isQuickBill: 0,
       isSplitted: 0,
       isSync: 1,
       itemCount: items.length,
       kotDetails: [],
       kotNotPrintedProductsList: [],
       kotVoidDetails: [],
       lastKotValue: 0,
       lastModifiedTimeLocal: "",
       latitude: appSettings.captureGeoLocationOnOrder ? state.latitude : 0,
       longitude: appSettings.captureGeoLocationOnOrder ? state.longitude : 0,
       loyaltyID: appSettings.loyalty,
       netAmount: 0,
       netBill: window.roundDecimal(netBill),
       numReceiptPrints: 0,
       orderCreationTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
       orderDeliveredTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
       orderDeliveryAmount: 0,
       orderDeliveryDeviceID: "",
       orderDeliveryRemarks: "",
       orderDeliveryUser: "",
       orderDeliveryUserID: -1,
       orderID: `${
          (appSettings.customOrderPrefix.length ? appSettings.customOrderPrefix : "OR") + deviceID
       }${moment().format("YYYYMMDD")}-${id}`,
       invoiceNumber: String(id),
       orderRemarks: "",
       orderType: "Takeaway",
       outstandingAmount: 0,
       paxSize: 0,
       payableAmount: Number(roundedGrossBill),
       paymentList: [],
       paymentStatus: "PAID",
       pineLabTransactionID: "",
       pinelabAmount: 0,
       posDate: moment().format("YYYY-MM-DD"),
       queueNumber: "",
       rounding: Number(rounding),
       samvat: "",
       serverID: -1,
       splitTableID: -1,
       splitTableName: "",
       tableID: -1,
       tableName: "",
       taxes: window.roundDecimal(taxesSummedUp),
       tempOrderID: -1,
       timezone: authData.timezone,
       totalChargeValue: Number(totalChargeValue),
       totalItems: items.length,
       totalProductLevelAdditionalCharges: 0,
       userName: authData.username.toString(),
       voucherID: -1,
       accessToken: token,
       storeID: +storeID,
       requestType: "ORDER",
    };
 
    return billingPayload;
 };
 
/* This is discount calculation function */
export const handleDiscount = (cartItems, state, appSettings, chargeTax) => {
    let deviceID = localStorage.getItem("deviceID");
    const { taxList = [] } = chargeTax;
    const { discountsList, discountMapped, selectedDiscount, selectedCustomer } = state;
    let billingStoreDetails = JSON.parse(localStorage.getItem("billingStoreDetails")),
       isInterstate = false;
    if (appSettings.enableGSTValidation && selectedCustomer.customerID) {
       if (selectedCustomer.customerAddress && selectedCustomer.customerAddress.length) {
          let shippingAddress = selectedCustomer.customerAddress?.find(
             (el) => el.addressType === "SHIPPING"
          );
          if (
             selectedCustomer.customerType === "COMPANY" &&
             shippingAddress &&
             shippingAddress.state != billingStoreDetails.outletAddState
          ) {
             isInterstate = true;
          }
       }
    }
    cartItems = cartItems?.map((e) => {
       const { priceIncludesTaxes, price, taxIDs, modifiersList } = e;
       let modifierValueSum = 0,
          productBasePrice = 0,
          itemSales = 0;
 
       /* Handling modifiers present in each item present in Cart */
       let modifiedModifiersList = modifiersList?.map((modifier) => {
          let copy = JSON.parse(JSON.stringify(modifier));
          copy.modifierQuantity = e.quantity;
          copy.modifierActualPrice = modifier.modifierPrice;
          copy.modifierPrice = Number(e.quantity) * Number(modifier.modifierPrice);
          return copy;
       });
       e.modifiersList = modifiedModifiersList || [];
       if (modifiedModifiersList && modifiedModifiersList.length) {
          modifierValueSum = modifiedModifiersList?.reduce(
             (acc, elem) => acc + Number(elem.modifierPrice),
             0
          );
       }
       if (e.productType == "COMBO") {
          const { comboProductsList = [] } = JSON.parse(JSON.stringify(e));
          let total = 0;
          let modifiedComboProductsList = comboProductsList.map((elem) => {
             let modifiedModifiersList = elem.modifiersList.map((modifier) => {
                let copy = JSON.parse(JSON.stringify(modifier));
                copy.modifierQuantity = elem.quantity;
                copy.modifierActualPrice = modifier.modifierPrice;
                copy.modifierPrice = Number(elem.quantity) * Number(modifier.modifierPrice);
                return copy;
             });
             elem.modifiersList = modifiedModifiersList;
             let totalModifierValue = modifiedModifiersList?.reduce(
                (acc, elem) => acc + elem.modifierPrice,
                0
             );
             elem.totalModifierValue = totalModifierValue;
             total += totalModifierValue;
             return elem;
          });
          e.totalModifierValue = total;
          modifierValueSum = total;
          e.comboProductsList = modifiedComboProductsList;
       }
       /* Calculation for productBasePrice ( Inclusive & Exclisive of taxes ) */
       if (priceIncludesTaxes) {
          let taxesApplied = taxList.filter(
             (tax) =>
                taxIDs &&
                taxIDs
                   .toString()
                   .split(",")
                   .map((elem) => Number(elem))
                   .includes(tax.taxID) &&
                (isInterstate ? tax.isIGST != 0 : tax.isIGST == 0)
          );
          let totalTaxPercentage = taxesApplied.reduce(
             (acc, elem) => acc + Number(elem.taxPercentage),
             0
          );
          productBasePrice = (Number(price) * 100) / (100 + totalTaxPercentage);
          modifierValueSum = (Number(modifierValueSum) * 100) / (100 + totalTaxPercentage);
       } else {
          productBasePrice = Number(price);
       }
       modifierValueSum = window.roundDecimal(modifierValueSum);
       itemSales = window.roundDecimal(
          Number(productBasePrice) * Number(e.quantity) + Number(modifierValueSum)
       );
       e.productBasePrice = window.roundDecimal(productBasePrice);
       e.totalModifierValue = window.roundDecimal(modifierValueSum);
       e.itemSales = itemSales;
       /* Adding Discounts in each item present in Cart */
       const filteredDiscounts = discountMapped
          ?.filter(
             (discount) =>
                discount.discountProductList
                   .toString()
                   .split(",")
                   .map((elem) => Number(elem))
                   .includes(
                      (e.productID && e.productID) || (e.comboProductID && e.comboProductID)
                   ) && selectedDiscount.includes(discount.discountID)
          )
          .map((e) => e.discountID);
       let discounts = [];
       discounts = discountsList?.filter(
          (dis) =>
             ((dis.isRuntimeDiscount && dis.discountOnProds.includes(e.productID)) ||
                (filteredDiscounts.includes(dis.discountID) &&
                   dis.isRuntimeDiscount == undefined)) &&
             !dis.isMembershipDiscount
       );
       let productLevelDiscounts = discounts?.filter((dis) => dis.discountLevel === "PRODUCT");
       productLevelDiscounts = productLevelDiscounts?.map((elem) => {
          let discountPrice = 0;
          const { discountType, discountValue } = elem;
          if (discountType == "ABSOLUTE") {
             if (discountValue > e.itemSales) {
                discountPrice = e.itemSales;
             } else {
                discountPrice = discountValue * e.quantity;
             }
          } else if (discountType == "PERCENTAGE") {
             discountPrice = discountValue * 0.01 * e.itemSales;
          }
          return {
             accountID: elem.isRuntimeDiscount ? 12 : elem.accountID,
             accountName: elem.isRuntimeDiscount ? "RuntimeDiscount" : elem.accountName,
             applyDiscToTargetProductList: 0,
             baseProductMapIfTargetApplicable: [],
             creationTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
             discountID: elem.discountID,
             discountLevel: elem.discountLevel,
             discountName: elem.discountName,
             discountPrice: window.roundDecimal(discountPrice),
             discountProductList: "",
             discountQty: 1,
             discountRemarks: "",
             discountType: elem.discountType,
             discountValue: Number(elem.discountValue),
             eligibleOrderItemList: [],
             endDateLocal: "",
             endDateUTC: "",
             isActive: 0,
             isApplicableToAllVariants: 0,
             isAppliedToBaseProductOnly: 1,
             isAutoApplied: 0,
             isMembershipDiscount: Boolean(elem.isMembershipDiscount) ? 1 : 0,
             isRuntimeDiscount: Boolean(elem.isRuntimeDiscount) ? 1 : 0,
             isTaxed: elem.isTaxed,
             loyaltyPointsUsed: 0,
             maximumOff: 0,
             minimumAmount: 0,
             minimumProductsRequired: -1,
             oldStoreList: "",
             deviceID,
             orderLevelAppliedDiscountValue: 0,
             orderLevelDiscountType: "",
             productMap: [],
             startDateLocal: "",
             startDateUTC: "",
             stores: [],
             targetProductList: "",
             targetProductNum: 0,
             timezone: "",
             userID: -1,
          };
       });
       /* Handling Membership Discounts */
       let membershipTracker = e.membershipTracker;
       if (membershipTracker && membershipTracker.membershipName) {
          let membershipPrice = 0;
          const { discountType, discountValue } = membershipTracker;
          const totalBasePriceForDiscount = e.productBasePrice * membershipTracker.leftToAvail;
          const totalModifierPriceForDiscount = e.modifiersList?.reduce((acc, modifier) => acc + modifier.modifierActualPrice * membershipTracker.leftToAvail, 0);
          const totalPriceForDiscount = totalBasePriceForDiscount + totalModifierPriceForDiscount;
          if (discountType == "ABSOLUTE") {
             if (discountValue > totalPriceForDiscount) {
                membershipPrice = totalPriceForDiscount;
             } else {
                membershipPrice = discountValue * membershipTracker.leftToAvail;
             }
          } else if (discountType == "PERCENTAGE") {
             membershipPrice = discountValue * 0.01 * totalPriceForDiscount;
          }
          const discountPayload = {
             accountID: 12,
             accountName: "RuntimeDiscount",
             applyDiscToTargetProductList: 0,
             baseProductMapIfTargetApplicable: [],
             creationTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
             discountID: Number(Math.random().toString().substr(2, 6)),
             discountLevel: "PRODUCT",
             discountName: membershipTracker.membershipName,
             discountPrice: window.roundDecimal(membershipPrice),
             discountProductList: "",
             discountQty: 1,
             discountRemarks: "",
             discountType: discountType,
             discountValue: Number(discountValue),
             eligibleOrderItemList: [],
             endDateLocal: "",
             endDateUTC: "",
             isActive: 0,
             isApplicableToAllVariants: 0,
             isAppliedToBaseProductOnly: 1,
             isAutoApplied: 0,
             isMembershipDiscount: 1,
             isRuntimeDiscount: 1,
             isTaxed: 1,
             loyaltyPointsUsed: 0,
             maximumOff: 0,
             minimumAmount: 0,
             minimumProductsRequired: -1,
             oldStoreList: "",
             deviceID,
             orderLevelAppliedDiscountValue: 0,
             orderLevelDiscountType: "",
             productMap: [],
             startDateLocal: "",
             startDateUTC: "",
             stores: [],
             targetProductList: "",
             targetProductNum: 0,
             timezone: "",
             userID: -1,
          };
          productLevelDiscounts = [discountPayload, ...productLevelDiscounts];
       }
       let allDiscounts = [],
          discountPrice = 0;
       if (productLevelDiscounts && productLevelDiscounts.length) {
          productLevelDiscounts = productLevelDiscounts.sort(
             (b, a) => a.discountPrice - b.discountPrice
          );
          allDiscounts = [productLevelDiscounts[0]];
          discountPrice = productLevelDiscounts[0].discountPrice;
       }
       e.discounts = allDiscounts;
       e.appliedDiscount = allDiscounts;
       e.totalDiscountPrice = discountPrice;
       return e;
    });
    /* Handling for Order Level Discounts applied on the order */
    cartItems = cartItems?.map((e) => {
       const filteredDiscounts = discountMapped
          ?.filter(
             (discount) =>
                discount.discountProductList
                   .toString()
                   .split(",")
                   .map((elem) => Number(elem))
                   .includes(
                      (e.productID && e.productID) || (e.comboProductID && e.comboProductID)
                   ) && selectedDiscount.includes(discount.discountID)
          )
          .map((e) => e.discountID);
       let discounts = [];
       discounts = discountsList?.filter(
          (dis) =>
             (dis.isRuntimeDiscount && dis.discountOnProds.includes(e.productID)) ||
             (filteredDiscounts.includes(dis.discountID) && dis.isRuntimeDiscount == undefined)
       );
       let orderLevelDiscounts = discounts?.filter((dis) => dis.discountLevel !== "PRODUCT");
       orderLevelDiscounts = orderLevelDiscounts?.map((elem) => {
          let totalItemSales = 0;
          let filteredDiscountMapped = discountMapped?.find(
             (dis) => dis.discountID === elem.discountID
          );
          let filteredDiscountProdList = filteredDiscountMapped?.discountProductList
             ?.split(",")
             ?.map((el) => +el);
          let applicableCartItems = cartItems?.filter((prod) =>
             filteredDiscountProdList.includes(prod.productID)
          );
          totalItemSales = applicableCartItems?.reduce(
             (acc, prod) => acc + (prod.itemSales - prod.totalDiscountPrice),
             0
          );
          let discountPrice = 0,
             discountPercent = ((e.itemSales - e.totalDiscountPrice) / totalItemSales) * 100;
          const { discountType, discountValue } = elem;
          if (discountType === "PERCENTAGE") {
             discountPrice = discountValue * 0.01 * (e.itemSales - e.totalDiscountPrice);
          } else if (discountType === "ABSOLUTE") {
             discountPrice = discountPercent * 0.01 * discountValue;
          }
          return {
             accountID: elem.isRuntimeDiscount ? 12 : elem.accountID,
             accountName: elem.isRuntimeDiscount ? "RuntimeDiscount" : elem.accountName,
             applyDiscToTargetProductList: 0,
             baseProductMapIfTargetApplicable: [],
             creationTimeLocal: moment().format("YYYY-MM-DD HH:mm:ss"),
             discountID: elem.discountID,
             discountLevel: elem.discountLevel,
             discountName: elem.discountName,
             discountPrice:
                discountPrice > e.itemSales - e.totalDiscountPrice
                   ? e.itemSales - e.totalDiscountPrice
                   : window.roundDecimal(discountPrice),
             discountProductList: "",
             discountQty: 1,
             discountRemarks: "",
             discountType: elem.discountType,
             discountValue: Number(elem.discountValue),
             eligibleOrderItemList: [],
             endDateLocal: "",
             endDateUTC: "",
             isActive: 0,
             isApplicableToAllVariants: 0,
             isAppliedToBaseProductOnly: 1,
             isAutoApplied: 0,
             isMembershipDiscount: Boolean(elem.isMembershipDiscount) ? 1 : 0,
             isRuntimeDiscount: Boolean(elem.isRuntimeDiscount) ? 1 : 0,
             isTaxed: elem.isTaxed,
             loyaltyPointsUsed: 0,
             maximumOff: 0,
             minimumAmount: 0,
             minimumProductsRequired: -1,
             oldStoreList: "",
             deviceID,
             orderLevelAppliedDiscountValue: 0,
             orderLevelDiscountType: "",
             productMap: [],
             startDateLocal: "",
             startDateUTC: "",
             stores: [],
             targetProductList: "",
             targetProductNum: 0,
             timezone: "",
             userID: -1,
          };
       });
       let allDiscounts = [...e.discounts];
       if (orderLevelDiscounts && orderLevelDiscounts.length) {
          orderLevelDiscounts = orderLevelDiscounts.sort(
             (b, a) => a.discountPrice - b.discountPrice
          );
          allDiscounts = [...allDiscounts, orderLevelDiscounts[0]];
       }
       e.discounts = allDiscounts;
       e.appliedDiscount = allDiscounts;
       return e;
    });
    return cartItems;
 };
 export const superScan = (barcode, products, productGroups, operation,operationType,uniqueAttributesList=[]) => {
   const searchParameter=["barcode","groupBarcode"]
   searchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));
   const inputLower = String(barcode).toLowerCase();
   let getScnnedProducts = new Promise((resolve, reject) => {
      if (!products.length || !barcode.toString().length || !operation) {
         reject({ message: "please provide all arguments" });
      }

      const isBatchedProductAllowed = ["Stock In", "Stock Requisition"].includes(operation);

      let scannedProducts = isBatchedProductAllowed
         ? products.filter((el) => el.barcode == barcode)
         : products.filter((el) => el.barcode == barcode && el.stockItemsType == "UNBATCHED");
      resolve(scannedProducts || []);
   });

   let getScnnedProductGroups = new Promise((resolve, reject) => {
      // let scannedProductGroups = productGroups.filter(
      //    (el) => el.barcode == barcode || el.groupBarcode == barcode
      // );
      let scannedProductGroups =
      productGroups &&
      productGroups.filter((item) => {
         const reqStr = searchParameter.reduce(
            (accumulator, param) => accumulator + item[param],
            ""
         );
         return reqStr.toLowerCase().includes(inputLower);
      });
      resolve(scannedProductGroups || []);
   });

   return Promise.all([getScnnedProducts, getScnnedProductGroups])
      .then((res) => {
         return { message: "Searched products.", results: [...res[0], ...res[1]] };
      })
      .catch((err) => {
         return { message: "something went wrong", results: [] };
      });
};

export const superSearch = (input = "", products = [], productGroups = [], operation = "",operationType="", uniqueAttributesList = []) => {
   console.log(operationType, "operationType-s");
   let productSearchParameter = ["productID", "productName", "barcode", "sku"];
   let productGroupSearchParameter = [
      "productID",
      "productName",
      "barcode",
      "groupBarcode",
      "sku",
      "batchVariantName",
      "batchVariantID",
   ];
   productGroupSearchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));

   const isBatchedProductAllowed = ["Stock In", "Stock Requisition"].includes(operation);
   console.log(isBatchedProductAllowed, "isBatchedProductAllowed");

   const productsList = isBatchedProductAllowed
      ? products
      : products.filter((el) => el.stockItemsType == "UNBATCHED");

   if (isNaN(Number(input)) && input.length > 2) {
      let searchedProducts = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productSearchParameter, productsList));
      });

      let searchedProductGroups = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productGroupSearchParameter, productGroups));
      });

      return Promise.all([searchedProducts, searchedProductGroups])
         .then((res) => {
            return { message: "Searched products.", results: [...res[0], ...res[1]] };
         })
         .catch((err) => {
            return { message: "something went wrong", results: [] };
         });
   } else if (!isNaN(Number(input)) && input > 0) {
      let searchedProducts = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productSearchParameter, productsList));
      });

      let searchedProductGroups = new Promise((resolve, reject) => {
         resolve(handleInputChange(input, productGroupSearchParameter, productGroups));
      });

      return Promise.all([searchedProducts, searchedProductGroups])
         .then((res) => {
            return { message: "Searched products.", results: [...res[0], ...res[1]] };
         })
         .catch((err) => {
            return { message: "something went wrong", results: [] };
         });
   } else {
      return Promise.resolve({
         message: "not searched yet because entered input value not satisfied",
         results: [],
      });
   }
};

// using in super search function
const handleInputChange = (input, searchParameter, data = []) => {
   const inputLower = String(input).toLowerCase();
   console.log(data, "adasdasdasdasd");
   const filteredData =
      data &&
      data.filter((item) => {
         const reqStr = searchParameter.reduce(
            (accumulator, param) => accumulator + item[param],
            ""
         );
         return reqStr.toLowerCase().includes(inputLower);
      });
   return filteredData;
};

// using in super search function
const removeDuplicates = (arr1, arr2) => {
   const combinedArray = arr1.concat(arr2);

   const uniqueObjects = {};

   combinedArray.forEach((obj) => {
      const key = obj.productID + String(obj.batchVariantID || "");
      if (!uniqueObjects[key]) {
         uniqueObjects[key] = obj;
      }
   });
   console.log(uniqueObjects);

   return Object.values(uniqueObjects);
};

export const superCartSearch = (input = "", products = [],uniqueAttributesList=[]) => {
   let searchParameter = [
      "productID",
      "productName",
      "barcode",
      "sku",
      "batchVariantName",
      "batchVariantID",
   ];
   searchParameter.push(...uniqueAttributesList.map((el) => el.linkedTo?.toString()));

   return handleInputChange(input, searchParameter, products);
};

export const superTaxCalculation = ({ product = {}, taxList = [], locationState }) => {
   const { operation, basicInfo } = locationState;
   const { pin, purchaseOrderSummary,vendorBillSummary, selectedVendor, sourceWarehouse, destinationWarehouse } = basicInfo;

   if(!basicInfo.isApplyTaxes){
      product.taxIDs = "";
      product.taxNames = "";
      product.taxPercentages = "";
      product.taxValues = "";
      product.totalTaxValue = 0;
      product.taxableValue=product.displayCostPriceToDisplay;
      product.taxableAmount=product.displayCostPriceToDisplay;
      product.productValue=product.displayCostPriceToDisplay;
      product.productBasePrice = product.displayCostPriceToDisplay;
      product.productActualPrice = product.displayCostPriceToDisplay;
      product.displayProductActualPrice = `${product.displayCostPriceToDisplay}.000`;

      product.totalTaxValuePurchaseReturn = 0;

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalPurchaseReturnPrice =
            Number(product.displayCostPrice) * Number(product.purchaseReturnQty);
         product.itemSalesPurchaseReturn = (
            Number(product.productBasePrice) * Number(product.purchaseReturnQty)
         ).toFixed(4);
         product.taxableValuePurchaseReturn = product.itemSalesPurchaseReturn;
         console.log(basicInfo,product,'basicInfo-12')
      }

      if (operation == "Stock Audit") {
         product.quantity =
            Math.ceil(Number(product.inventory)) -
            Number(product.displayQuantity) * product.conversionFactor;
   
         product.totalVariantPrice2 = product.totalVariantPrice;
   
         product.totalVariantPrice = Math.abs(product.quantity * product.costPrice);
         product.oldQuantity = product.inventory;
         product.newQuantity = product.displayQuantity;
      }
     
      return product;

   }
    

   product.costPrice = (product.displayCostPriceToDisplay / product.conversionFactor).toFixed(1); // resetting cost price so that tax value added only once.
   product.productBasePrice = product.costPrice; 
   product.productActualPrice = product.costPrice; 

   if (typeof product.taxIDs == "number") {
      product.taxIDs = product.taxIDs.toString();
   }

   let taxes = (product.taxIDs && product?.taxIDs?.split(",")) || [];
   let mappedTaxesWithProduct = taxList.filter((element) => taxes.includes(`${element.taxID}`));

   if (pin == "PURCHASE-ORDER" && operation != "Stock Out") {    //
      let source = getSourceObject(locationState, purchaseOrderSummary.sourceID);
      console.log(source, "sourcesourcesource", locationState, purchaseOrderSummary);
      if (
         source?.outletAddCountry == "India" &&
         selectedVendor.country == "India" &&
         source?.outletAddState != selectedVendor.state
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
      }
   }

   else if (pin == "VENDOR-BILL" && operation != "Stock Out") {
      let source = getSourceObject(locationState, vendorBillSummary.sourceID);
      console.log(source, "sourcesourcesource", locationState, vendorBillSummary);
      if (
         source?.outletAddCountry == "India" &&
         selectedVendor.country == "India" &&
         source?.outletAddState != selectedVendor.state
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
      }
   }
   
   else if (
      operation == "Stock Transfer" ||
      (pin == "TRANSACTION-ID" && operation == "Stock In")
   ) {
      let source = getSourceObject(locationState, sourceWarehouse.value);
      let destination = getSourceObject(locationState, destinationWarehouse.value);

      if (
         source.outletAddCountry == "India" &&
         destination.outletAddCountry == "India" &&
         source.outletAddState != destination.outletAddState
      ) {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((t) => t.isIGST == 1);
      } else {
         mappedTaxesWithProduct = mappedTaxesWithProduct.filter((t) => t.isIGST != 1);
      }
   } else {
      mappedTaxesWithProduct = mappedTaxesWithProduct.filter((el) => el.isIGST != 1);
   }

   const totalTaxPercentage =
      mappedTaxesWithProduct.reduce((ttl, i) => ttl + i.taxPercentage, 0) || 0;

   const taxPercentagesList = mappedTaxesWithProduct.map((b) => b.taxPercentage);
   let taxValuesList = taxPercentagesList.map((c) =>
      (
         Number(product.displayCostPriceToDisplay) *
         (Number(c) / 100) *
         Number(product.displayQuantity)
      ).toFixed(4)
   );

   let taxValuesListPoReturn = taxPercentagesList.map((c) =>
      (
         Number(product.displayCostPriceToDisplay) *
            (+c / 100) *
            Number(product.purchaseReturnQty) || 1
      ).toFixed(4)
   );

   let taxNames = mappedTaxesWithProduct.map((el) => el.taxName);
   let taxIDS = mappedTaxesWithProduct.map((el) => el.taxID);

   let calculatedTaxAmountOnCostPrice = product.costPrice * (totalTaxPercentage / 100);

   let calculatedTaxAmountOnDisplayCostPrice =
      product.displayCostPriceToDisplay * (totalTaxPercentage / 100);

   if (!product?.priceIncludesTaxes) {
      // && !product.batchVariantID -> this needs to be handled
      //not inclusive case
      product.displayProductActualPrice = (Number(product.displayCostPriceToDisplay)).toFixed(4);
      product.costPrice = (calculatedTaxAmountOnCostPrice + Number(product.costPrice)).toFixed(4);

      product.displayCostPrice = (
         Number(product.displayCostPriceToDisplay) + calculatedTaxAmountOnDisplayCostPrice
      ).toFixed(4);

      product.totalTaxValue =
         calculatedTaxAmountOnDisplayCostPrice * Number(product.displayQuantity);

      product.productValue =
         Number(product.productBasePrice) * Number(product.quantity) + // product.displayCostPriceToDisplay *  product.displayQuantity
         calculatedTaxAmountOnDisplayCostPrice * Number(product.displayQuantity);

      product.taxValues = taxValuesList.join(",");

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalTaxValuePurchaseReturn =
            calculatedTaxAmountOnDisplayCostPrice * Number(product.purchaseReturnQty);

         product.productValuePurchaseReturn =
            Number(product.productBasePrice) * Number(product.purchaseReturnQty) +
            +calculatedTaxAmountOnDisplayCostPrice * Number(product.purchaseReturnQty);

         product.taxValuesPurchaseReturn = taxValuesListPoReturn.join(",");
      }
   } else {
      // inclusive case
      // 100*price / (100+tax)
      let calculatedTaxAmountOnDisplayCostPrice =
         (product.displayCostPriceToDisplay * 100) / (totalTaxPercentage + 100);

      calculatedTaxAmountOnDisplayCostPrice =
         product.displayCostPriceToDisplay - calculatedTaxAmountOnDisplayCostPrice;

      // taxValuesList = taxPercentagesList.map((c) =>
      //    ((Number(product.displayCostPriceToDisplay) * 100) / (Number(c) + 100)).toFixed(4)
      // );

      product.productBasePrice = Number(
         ((100 * product.costPrice) / (100 + totalTaxPercentage)).toFixed(4)   //reverse tax calculation
      );

      product.productActualPrice = product.costPrice;  // inclusive k case mein productBasePrice and productActulaPrice different hoga
      product.displayProductActualPrice = `${product.displayCostPriceToDisplay}.000`;

      product.totalTaxValue = (
         Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
         (totalTaxPercentage / 100) *
         product.displayQuantity
      ).toFixed(4);

      product.productValue = (
         Number(
            ((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
               Number(product.displayQuantity)
         ) + Number(product.totalTaxValue)
      ).toFixed(4);

      product.taxValues = taxPercentagesList
         .map((c) =>
            (
               (Number(c) / totalTaxPercentage) *
               (Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
                  (totalTaxPercentage / 100)) *
               Number(product.displayQuantity)
            ).toFixed(4)
         )
         .join(",");

      product.totalPrices = Number(product.displayCostPriceToDisplay) * product.displayQuantity;
      product.totalPrice = Number(product.displayCostPriceToDisplay) * product.displayQuantity;

      if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
         product.totalTaxValuePurchaseReturn = Number(
            (
               ((100 * Number(product.displayCostPriceToDisplay)) / (100 + totalTaxPercentage)) *
               (totalTaxPercentage / 100) *
               Number(product.purchaseReturnQty)
            ).toFixed(4)
         );

         product.productValuePurchaseReturn = (
            Number(product.productBasePrice) * Number(product.purchaseReturnQty) +
            Number(calculatedTaxAmountOnDisplayCostPrice) * Number(product.purchaseReturnQty)
         ).toFixed(2);

         product.taxValuesPurchaseReturn = taxPercentagesList
            .map((c) =>
               (
                  (Number(c) / totalTaxPercentage) *
                  (Number((100 * product.displayCostPriceToDisplay) / (100 + totalTaxPercentage)) *
                     (totalTaxPercentage / 100)) *
                  Number(product.purchaseReturnQty)
               ).toFixed(4)
            )
            .join(",");
      }
   }

   if (pin == "PURCHASE-ORDER" || pin == "VENDOR-BILL") {
      product.totalPurchaseReturnPrice =
         Number(product.displayCostPrice) * Number(product.purchaseReturnQty);
      product.itemSalesPurchaseReturn = (
         Number(product.productBasePrice) * Number(product.purchaseReturnQty)
      ).toFixed(4);
      product.taxableValuePurchaseReturn = product.itemSalesPurchaseReturn;
   }

   product.itemSales = (Number(product.productBasePrice) * Number(product.quantity)).toFixed(4);
   product.taxableValue = product.itemSales;
   product.taxableAmount = product.itemSales;
   product.taxIDs = taxIDS.join(",");
   product.taxNames = taxNames.join(",");
   product.taxPercentages = taxPercentagesList.join(",");
   product.totalVariantPrice = Number(product.displayCostPrice) * Number(product.displayQuantity);
   product.price = Number(product.displayCostPrice) * Number(product.displayQuantity);

   if (operation == "Stock Audit") {
      product.quantity =
         Math.ceil(Number(product.inventory)) -
         Number(product.displayQuantity) * product.conversionFactor;

      product.totalVariantPrice2 = product.totalVariantPrice;

      product.totalVariantPrice = Math.abs(product.quantity * product.costPrice);
      product.oldQuantity = product.inventory;
      product.newQuantity = product.displayQuantity;
   }
   debugger;
   return product;
};

const getSourceObject = (locationState, sourceID) => {
   const { basicInfo } = locationState;
   const { chainStoreList } = basicInfo;

   let wareList =
      (chainStoreList &&
         chainStoreList.warehousesData?.map((u) => {
            u["storeID"] = u.warehouseID;
            u.outletAddCountry = u.warehouseAddCountry;
            u.outletAddState = u.warehouseAddState;
            return u;
         })) ||
      [];

   let storeWareList = chainStoreList ? [...chainStoreList.data, ...wareList] : [];

   return storeWareList.find((el) => el.storeID == sourceID);
};

export const checkBatchedProductQuantityExceedsRemaining = (product, productList) => {
   const result = {};

   if (
      product &&
      product.stockItemsType == "BATCHED" &&
      (!product.batchVariantID || Number(product.batchVariantID) < 0)
   ) {
      productList.forEach((item) => {
         if (item.stockItemsType == "BATCHED" && item.productID === product.productID) {
            const currentProduct = result[item.productID] || {
               displayQuantity: 0,
               purchaseReturnQty: 0,
               isErrorFound: false,
            };

            const updatedDisplayQuantity =
               currentProduct.displayQuantity + Number(item.displayQuantity || 0);
            const updatedPurchaseReturnQty =
               currentProduct.purchaseReturnQty + Number(item.purchaseReturnQty || 0);

            const isErrorFound =
               updatedDisplayQuantity + updatedPurchaseReturnQty > Number(item.remainingQty);

            result[item.productID] = {
               displayQuantity: updatedDisplayQuantity,
               purchaseReturnQty: updatedPurchaseReturnQty,
               isErrorFound: isErrorFound,
            };
         }
      });
   }
   debugger;
   return result[product.productID]?.isErrorFound || false;
};
